import Uppy from '@uppy/core';
import Tus from '@uppy/tus';

const uppy = new Uppy({
  meta: { type: 'avatar' },
  restrictions: { maxNumberOfFiles: 200 },
  autoProceed: true,
  allowMultipleUploadBatches: true,
});

uppy.use(Tus, {
  endpoint: '/files/',
  removeFingerprintOnSuccess: true,
  limit: 4,
  chunkSize: 10 * 1024 * 1024, // 10 MB
});

export default uppy;
